import * as React from "react";
import Hero from "../components/hero";
import Footer from "../components/footer";
// markup
const ThankYouPage = () => {
  return (
    <main>
      <title>Thank You - VTAKU</title>
      <Hero />
      <p class="text-center">
        <strong>Thank You! We will be in touch with you shortly.</strong>
      </p>
      <Footer />
    </main>
  );
};

export default ThankYouPage;
